import {getConfig, isNotNull, sendMails} from "../Utils";
const axios = require('axios');
import {BASKET_URL, PWA_SHORT_URL} from "@/assets/js/constantUtils";
var app = getConfig();

var basket = {
    basketProducts: [],
    lockedProducts: [],
    totalAmount: 0
};

var fullBasket = [];

function initBasket(){
    if(JSON.parse(localStorage.getItem("DKC_basket"))){
        basket = JSON.parse(localStorage.getItem("DKC_basket"));
    }
}

export function getBasket() {
    initBasket();
    return basket;
}

export function addProductToLocalBasket(productToAdd){
    var added = false;
    var productEan = productToAdd.ean.split("_")[0];
    initBasket();
    let searchProduct = basket.basketProducts.filter(cartProduct => cartProduct.product.id == productToAdd.id)
    if(searchProduct.length > 0){
        searchProduct[0].qty += 1
    }else{
        var tmpProduct = {};
        tmpProduct.id = Math.floor(Math.random() * Math.pow(10, 4));
        tmpProduct.qty = 1;
        tmpProduct.qtyLocked = false;
    
        if(productToAdd.ean.indexOf("_") > -1){
            tmpProduct.qtyLocked = true;
            basket.lockedProducts.push(productToAdd.ean);
            productToAdd.ean = productEan;
        }
        tmpProduct.product = productToAdd;
        basket.basketProducts.push(tmpProduct);
    }
    basket.totalAmount += productToAdd.price;
    localStorage.setItem("DKC_basket", JSON.stringify(basket));
}

export function decreaseProductFromLocalBasket(productToDecrease) {
    initBasket();
    basket.basketProducts.some(function(element, index){
        if (element.product.id == productToDecrease.id && !element.qtyLocked) {
            if (element.qty > 1) {
                element.qty -= 1;
            } else {
                basket.basketProducts.splice(index, 1);
            }
            basket.totalAmount -= productToDecrease.price;
            return true;
        }
    })
    localStorage.setItem("DKC_basket", JSON.stringify(basket));
}

export function deleteProductFromLocalBasket(productToDelete) {
    initBasket();
    basket.basketProducts.forEach(function(element, index) {
        if (element.id == productToDelete.id) {
            basket.basketProducts.splice(index, 1);
            if(element.qtyLocked){
                var tmpLockedProducts = [];
                basket.lockedProducts.forEach(function(lockedProductEan) {
                    if(lockedProductEan.split("_")[0] != element.product.ean){
                        tmpLockedProducts.push(lockedProductEan);
                    }
                });
                basket.lockedProducts = tmpLockedProducts;
            }
            basket.totalAmount -= productToDelete.product.price * productToDelete.qty;
        }
    })
    localStorage.setItem("DKC_basket", JSON.stringify(basket));
}

export function emptyLocalBasket() {
    initBasket();
    basket.basketProducts = [];
    basket.lockedProducts = [];
    basket.totalAmount = 0;
    localStorage.setItem("DKC_basket", JSON.stringify(basket));
    app.appContext.basketChange();
}


function formatBasketBeforeUpdate() {
    let localBasket = JSON.parse(localStorage.getItem('DKC_basket'));
    let formatedBasket = {
        productsCart: []
    };
    localBasket.basketProducts.forEach(function(product) {
        formatedBasket.productsCart.push({
            productId: product.product.id,
            qty: product.qty
        });
    })

    return formatedBasket;
}

export function getLocalBasket(){
    return JSON.parse(localStorage.getItem("DKC_basket"));
}

export function updateBasket(callback) {
    
    const formatedBasket = formatBasketBeforeUpdate();

    var postdata = new URLSearchParams();
    postdata.append("token", localStorage.getItem("IEC_TOKEN"));
    postdata.append("products", JSON.stringify(formatedBasket));
    axios.post(BASKET_URL.MANAGE_PRODUCTS(), postdata)
        .then(response => {
            if(response.status == 200){
                getCustomerBasket(callback)
            }
        })
}

export function validBasket(comment, callback) {
    var postdata = new URLSearchParams();
    postdata.append("token", localStorage.getItem("IEC_TOKEN"));
    postdata.append("orderOrigin", "IEC_CLICK_AND_COLLECT");
    postdata.append("comment", comment);
    axios.post(BASKET_URL.VALID_BASKET(), postdata)
        .then(response => {
            if(response.status == 200){
                localStorage.removeItem("IEC_SELECTED_SLOT")
                let data = response.data;
                localStorage.setItem("encodedTicketId", data.complexId);
                getCustomerBasket(callback);
                let ticketUrl = "";
                if(process.env.NODE_ENV === 'production'){
                    ticketUrl = PWA_SHORT_URL + "?tId=" + data.complexId ;
                }else{
                    ticketUrl = "http://localhost:8080/index" + "?tId=" + data.complexId ;
                }

                let loadedDemo = JSON.parse(sessionStorage.getItem("loadedDemo"))
                if(loadedDemo != null){
                    ticketUrl += '&d=' + loadedDemo.id
                }
                console.log(ticketUrl)
                
                let email = localStorage.getItem("email");
                let tel = JSON.parse(localStorage.getItem("phoneNumber"));
                let basket = JSON.parse(localStorage.getItem('DKC_basket'))
                
                if(tel != null && tel != ""){
                    let recipient = tel.formattedNumber.replace("+", "00")
                    let smsMessage = basket.orderNumber ? "McDonald's,\nVeuillez trouver votre e-ticket pour votre commande #"+ basket.orderNumber.toString().slice(-2) +" :\n" : "McDonald's,\nVeuillez trouver votre e-ticket pour votre commande:\n"
                    sendSMS(recipient, encodeURIComponent(smsMessage + ticketUrl));
                }

                if(email!=null && email != ""){
                    sendMail(email, encodeURIComponent(ticketUrl));
                }
            }
        })
}

export function setSuspect(callback) {
    var path = "/basket/set/suspect";
    var url = app.DKW_URL + path;
    var request = new XMLHttpRequest();
    var params = "token=" + localStorage.getItem("DKC_food_token");
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if(response.error == false){
                    getCustomerBasket(callback);
                }else{
                    app.appContext.showAlert(app.appContext.$t('errorValidBasket'),"error");
                }
            }else{
                app.appContext.showAlert(app.appContext.$t('errorValidBasket'),"error");
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}

export function getCustomerBasket(callback) {
    let token = localStorage.getItem("IEC_TOKEN");
    fullBasket = {
        id: 0,
        totalAmount: 0,
        basketProducts: [],
        lockedProducts:[]
    };
    if(token){
        axios.get(BASKET_URL.GET_BASKET_BY_STATE().format({token, state: 'IN_PROGRESS'}))
        .then(response => {
            if(response.status == 200){
                let basketsList = response.data;
                basketsList.forEach(function(customerBasket) {
                    if (customerBasket.state == "IN_PROGRESS") {
                        fullBasket.complexId = customerBasket.complexId;
                        fullBasket.id = customerBasket.id;
                        fullBasket.totalAmount = customerBasket.totalAmount;
                        fullBasket.basketProducts = customerBasket.basketProducts;
                        fullBasket.orderNumber = customerBasket.orderNumber;
                        fullBasket.state = customerBasket.state;
                    }
                });
                if(callback != null){
                    callback(fullBasket)
                }
            }
        })
    }else{
        callback(fullBasket);
    }
}

export function getTicket(encodedTicketId, callback) {
    axios.get(BASKET_URL.GET_BASKET_BY_COMPLEX_ID().format({complexId: encodedTicketId}))
        .then(response =>{
            if(response.status == 200){
                callback(response.data)
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetTicket'),"error");
            }
        })
}

export function getKeyword(lang, callback) {
    var path = "/basket/get/keyword";
    var url = app.DKW_URL + path;
    var params = "?lang=" + lang;
    var request = new XMLHttpRequest();
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = request.response
                callback(response);
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetTicket'),"error");
            }
        }
    };
    request.open('GET', url + params, true);
    request.send();
}

export function clearBasket() {
    if(isNotNull(localStorage.getItem("DKC_food_token"))){
        app = getConfig();
        var path = "/basket/clear";
        var url = app.DKW_URL + path;
        var request = new XMLHttpRequest();
        var params = "token=" + localStorage.getItem("DKC_food_token");
        request.onreadystatechange = function() {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (request.status === 200) {
                    var response = JSON.parse(request.response);
                    if(response.error == false){
                        emptyLocalBasket();
                    }else{
                        app.appContext.showAlert(app.appContext.$t('errorClearBasket'),"error");
                    }
                }else{
                    app.appContext.showAlert(app.appContext.$t('errorClearBasket'),"error");
                }
            }
        };
        request.open('POST', url, true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        request.send(params);
    }
}

export function getProductQuantity(productToSearch){
    let localBasket = getLocalBasket()
    if(localBasket != null){
        let foundProduct = localBasket.basketProducts.filter(({product}) => product.id == productToSearch.id)
        return foundProduct[0] ? foundProduct[0].qty : 0
    }
    return 0
}

export function getBasketCount(){
    let localBasket = getLocalBasket()
    if(localBasket != null && localBasket.basketProducts != null){
        return localBasket.basketProducts.reduce((acc,basketProduct) => basketProduct.qty + acc,0)
    }
    return 0
}

export function initLanePayment(terminalId, orderId, callback){
    let postdata = new URLSearchParams();
    postdata.append("token", localStorage.getItem("IEC_TOKEN"));
    postdata.append("terminalId", terminalId);
    postdata.append("orderId", orderId);
    
    axios.post(BASKET_URL.INIT_LANE_PAYMENT(), postdata)
        .then(res => callback(res.data))
        .catch(res => callback(null))
}

export function checkLanePayment(transactionId){
    let postdata = new URLSearchParams();
    postdata.append("token", localStorage.getItem("IEC_TOKEN"));
    postdata.append("transactionId", transactionId);
    return axios.post(BASKET_URL.CHECK_LANE_PAYMENT(), postdata)
}
export function getCustomerBasketsByState(state){
    let token = localStorage.getItem("IEC_TOKEN");
    if(token){
        return axios.get(BASKET_URL.GET_BASKET_BY_STATE().format({token, state}))
    }
    return null
}

export function changeSubState(complexId, newSubState, callback){
    const params = new URLSearchParams();
    params.append("complexId", complexId)
    params.append("subState", newSubState)

    axios.post(BASKET_URL.CHANGE_SUBSTATE_BASKET(), params).then((response) => {
        if(response.status === 200){
            callback(response.data)
        }
    })
}